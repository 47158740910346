import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import baseImg1 from '../../assets/bipoc.jpg';
import baseImg2 from '../../assets/alt-med.jpg';
import baseImg3 from '../../assets/bod.jpg';
import baseImg4 from '../../assets/eso.jpg';
import baseImg5 from '../../assets/doula.jpg';
import baseImg6 from '../../assets/trans.jpg';
import css from './LandingPage.module.css';
import classNames from 'classnames';
import { showToast } from '../../util/toast';
// import { ToastContainer, toast } from 'material-react-toastify';
export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const handleClick = searchParams =>
    history.push({
      pathname: '/healers',
      search: searchParams,
    });
    // showToast("hey", "success")
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn className="landing">
        <LayoutWrapperTopbar>
          <TopbarContainer showEmailModal={false} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li
              className={css.section}
              style={{ backgroundColor: 'black', width: '100vw', marginLeft: '-40px' }}
            >
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li>
            {/* <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li> */}
          </ul>
          <div className="container-full">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="workSection-title push-top-xxlarge push-left-smedium">
                    <div className={css.title}>
                      <h2 className="landing-title black">
                        {intl.formatMessage({ id: 'LandingPage.sectionSeekingTitle' })}
                      </h2>
                    </div>
                    <p>{intl.formatMessage({ id: 'LandingPage.sectionSeekingText' })}</p>
                    <hr className="hrThick" />
                  </div>
                </div>
              </div>
              <div className="seekingBox">
                <div className={classNames('row', css.marginFixer, 'push-top-xlarge')}>
                  <div
                    className="col-md-4 relativeIndex mini-box"
                    onClick={() =>
                      handleClick(
                        '?pub_identities=has_any%3Aafrican%2Cafro-caribbean%2Cafro-latinx%2Carab%2Casian%2Cbi-racial%2Cblack%2Ccentral-american%2Ccentral-asian%2Ceast-asian%2Cfilipinx%2Cindigenous%2Clatinx%2Cmiddle-eastern%2Cmixed-race%2Cmultiethnic%2Cmultiracial%2Cperson-of-color%2Cpolynesian%2Csouth-american%2Csouth-asian%2Csoutheast-asian%2Ctagalog%2Cswana%2Cchicanx'
                      )
                    }
                  >
                    <img className="img-responsive" src={baseImg1} />
                    <h4>{intl.formatMessage({ id: 'LandingPage.bipocLink' })}</h4>
                  </div>

                  <div
                    className="col-md-4 relativeIndex mini-box"
                    onClick={() =>
                      handleClick(
                        '?pub_modalities=has_any%3Aayurvedic-medicine%2Cblack-folk-healing--hoodoo%2Cchinese-medicine%2Ceastern-medicine%2Cherbalism%2Chomeopathy%2Cnatural-medicine%2Cnaturopathy'
                      )
                    }
                  >
                    <img className="img-responsive" src={baseImg2} />
                    <h4>{intl.formatMessage({ id: 'LandingPage.alternativeMedicineLink' })}</h4>
                  </div>

                  <div
                    className="col-md-4 relativeIndex mini-box"
                    onClick={() =>
                      handleClick(
                        '?pub_modalities=has_any%3A13th-octave-lahochi-healing%2Cakashic-record-consulting%2Canimal-communication%2Castrology%2Ccoffee-reading%2Chypnotherapy%2Cinner-child-healing%2Cintergalactic-soul-retrieval%2Cintuitive-human-design-reading%2Cnumerology%2Cpalm-reading%2Cpast-life-regression%2Cpsychic--mediumship%2Csomatic-healing%2Ctarot-reading%2Ctea-reading'
                      )
                    }
                  >
                    <img className="img-responsive" src={baseImg4} />
                    <h4>{intl.formatMessage({ id: 'LandingPage.esotericLink' })}</h4>
                  </div>
                </div>

                <div className={classNames('row', css.marginFixer, 'push-top-small')}>
                  <div
                    className="col-md-4 relativeIndex mini-box"
                    onClick={() =>
                      handleClick(
                        '?pub_modalities=has_any%3Ayoga%2Cthai-massage%2Cscarwork%2Creflexology%2Cmyofascial%2Cmovement-healing--core-integration%2Cmassage%2Ccupping%2Ccuddle-therapy%2Ccraniosacral%2Cacupuncture%2Cacupressure'
                      )
                    }
                  >
                    <img className="img-responsive" src={baseImg3} />
                    <h4>{intl.formatMessage({ id: 'LandingPage.bodyWorkLink' })}</h4>
                  </div>

                  <div
                    className="col-md-4 relativeIndex mini-box"
                    onClick={() =>
                      handleClick(
                        '?pub_modalities=has_any%3Abirth-doula%2Cdeath-doula%2Cfull-spectrum-doula%2Cjourney--ceremony-guide%2Cout-of-hospital-birth-assistance'
                      )
                    }
                  >
                    <img className="img-responsive" src={baseImg5} />
                    <h4>{intl.formatMessage({ id: 'LandingPage.duolaWorkLink' })}</h4>
                  </div>

                  <div
                    className="col-md-4 relativeIndex mini-box"
                    onClick={() => handleClick('?pub_identities=has_any%3Atransgender')}
                  >
                    <img className="img-responsive" src={baseImg6} />
                    <h4>{intl.formatMessage({ id: 'LandingPage.transHealersLink' })}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames('row', css.marginFixer, 'black-bg')}>
              <div className="col-md-12"></div>
            </div>
            {/* <div className="container push-top-xxlarge">
              <div className="row">
                <div className="col test-box push-top-large">
                  <img className="img-responsive" src={test1} />
                  <div className="push-left-medium">
                    <div className="test-box-name white">
                      <h4>Hannah</h4>
                      <h3>Montreal, Canada</h3>
                    </div>
                    <p>I would love to deepen our collective practices of supporting each other's work and building abolitionist webs of accountability together!</p>
                  </div>
                </div>
                <div className="col test-box">
                  <h1>Real words from community</h1>
                  <img className="img-responsive" src={test2} />
                  <div className="push-left-medium">
                    <div className="test-box-name white">
                      <h4>Yin</h4>
                      <h3>Brooklyn, New York</h3>
                    </div>
                    <p>I find that working with my Queer community is not only deeply satisfying, but also healing for myself.</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
